import { shuffleArray } from "../helpers";
import Swiper from 'swiper'
import { Pagination, Autoplay } from 'swiper/modules';

const apiBaseUrl = "https://news.uncovr.com/api/v2";
//const apiBaseUrl = "/articles/api";

let uncovrNewsRessortsPromise;

const daysOfTheWeek = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
export async function loadNewsRessorts(force) {
    if (!uncovrNewsRessortsPromise || force) {
        uncovrNewsRessortsPromise = new Promise(async function (resolve) {
            const ressorts = {};
            const order = [];
            const loadedRessorts = await fetch(`${apiBaseUrl}/resorts`).then((d) => d.json());
            for (let ressort of loadedRessorts) {
                ressorts[ressort.Id] = {
                    id: ressort.Id,
                    name: ressort.Name,
                    color: ressort.Color
                }
                order.push(ressort.Id);
            }
            resolve({
                ressorts,
                order
            });
        });
    }

    return uncovrNewsRessortsPromise;
}

function parseApiNews(data) {
    return {
        id: data.Id,
        ressort: {
            id: data.ResortId,
            name: data.ResortName,
            color: data.ResortColor,
        },
        title: data.Title,
        text: data.Text,
        imageId: data.ImageId,
        date: new Date(data.Date),
        previewDate: data.PreviewDate ? new Date(data.PreviewDate) : null,
        editor: data.Editor,
        source: data.Source,
        tags: data.Tags,
        video: data.Video,
        mediaSource: data.MediaSource,
        regions: data.Regions,
        uncovrId: data.UncovrId,
        uncovrHasImg: data.UncovrHasImg,
        uncovrCompany: data.UncovrCompany
    }
}
function urlTitle(string) {
    return string
        .toLowerCase()
        .replaceAll("\u00F6", "oe")
        .replaceAll("\u00FC", "ue")
        .replaceAll("\u00E4", "ae")
        .replaceAll("\u00DF", "ss")
        .replace(/[^0-9a-z ]/g, '')
        .replaceAll(' ', '-')
}

function canonicalUrlForNews(news) {
    return `${window.location.hostname.startsWith("localhost") ? "" : window.location.hostname.startsWith("test") ? "https://test.uncovr.com" : "https://www.uncovr.com"}/Articles/${urlTitle(news.title)}-${news.id}`;
}

function imageUrlForNews(news) {
    return news.imageId ? `https://news.uncovr.com/Images/Get/${news.imageId}` : null;
}

function mapRegion(regionId) {
    switch (regionId) {
        case "1364362": return "AT";
        case "1000": return "DE";
        case "1381495": return "INT"
        default: return null;
    }
}

/**
 * @param {null|"ressort"|"date"|"tag"} mode
 * @param {{date: string, maxAgeHours: number, resortId: number, excludeResortId: number, tags: string, topNews: boolean, uncovrId: number, skip: number, take: number }} options
 * @param {AbortSignal} signal
 */
async function loadNewsList(mode, options,  signal) {
    const searchParams = new URLSearchParams(options);
    if (mode == "ressort") {
        return await fetch(`${apiBaseUrl}/news/grouped-resort?${searchParams.toString()}`, { signal }).then((d) => d.json()).then((d) => d.map((g) => {
            return {
                group: {
                    label: g.Resort.Name,
                    type: "ressort"
                },
                ressort: {
                    id: g.Resort.Id,
                    name: g.Resort.Name,
                    color: g.Resort.Color
                },
                items: g.News.map(parseApiNews)    
            }
        }));
    } else if (mode == "date") {
        return await fetch(`${apiBaseUrl}/news/grouped-date?${searchParams.toString()}`, { signal }).then((d) => d.json()).then((d) => d.map((g) => {
            return {
                group: {
                    label: g.Date,
                    type: "date"
                },
                date: new Date(g.Date),
                items: g.News.map(parseApiNews)
            }
        }));
    } else if (mode == "tag") {
        return await fetch(`${apiBaseUrl}/news/grouped-tag?${searchParams.toString()}`, { signal }).then((d) => d.json()).then((d) => d.map((g) => {
            return {
                group: {
                    label: g.Tag,
                    type: "tag"
                },
                items: g.News.map(parseApiNews)
            }
        }));
    } else {
        return await fetch(`${apiBaseUrl}/news?${searchParams.toString()}`, { signal }).then((d) => d.json()).then((d) => ({
            items: d.map(parseApiNews)
        }));
    }
}

async function loadNews(id) {
    return await fetch(`${apiBaseUrl}/news/${id}`).then((d) => d.json()).then(parseApiNews);
}

var newsImageObserver;
function handleNewsImages(el) {
    if (!newsImageObserver) {
        newsImageObserver = new IntersectionObserver((entries) => {
            if (entries.length) {
                for (let entry of entries) {
                    if (entry.isIntersecting && !entry.target.src) {
                        entry.target.src = entry.target.getAttribute("data-src");
                    }
                }
            }
        });
    }

    $(el).find(".uncovr-news__item__image[data-src]").each((i, img) => {
        newsImageObserver.observe(img);
        img.addEventListener("load", () => {
            img.classList.add("loaded");
        });
    });
}


async function fillByDate($wrapper, signal) {
    $wrapper.css('height', `${$wrapper.outerHeight()}px`);
    $('.uncovr-news', $wrapper).remove();

    const count = $wrapper.attr('data-count');
    const region = mapRegion($wrapper.attr('data-region'));
    const ressortId = $wrapper.attr('data-ressort');

    const newsList = (await loadNewsList("date", {
        ...(ressortId ? { resortId: ressortId } : {}),
        ...(count ? { take: count } : {}),
        ...(region ? { regions: region } : {})
    }, signal));


    if (signal.aborted) return;
    if (!newsList.length) return;

    for(let group of newsList) {
        const newsEls = [];

        for (let news of group.items) {
            newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}" style="--ressort-color: ${news.ressort.color}">
                        <img class="uncovr-news__item__image" data-src="${imageUrlForNews(news)}" alt="image accompanying press release">
                        <div class="uncovr-news__item__content">
                            <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                        </div>
                        <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                    </article>`);
        }
    
        const newsSectionEl = $(`<section class="uncovr-news uncovr-news--by-date"></section>`);
    
        const date = group.date;
        const dateEl = $(`<div class="uncovr-news__date__wrapper">
                    <h2 class="uncovr-news__date"><strong>${daysOfTheWeek[date.getDay()]}</strong>${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.</h2>
                </div>`);

        newsSectionEl.append(dateEl); 
        newsSectionEl.append(newsEls.join(''));
        $wrapper.append(newsSectionEl);
        handleNewsImages(newsSectionEl);
    }

    $wrapper.css('height', 'auto');
}

async function fillByPreview($wrapper, signal) {
    $wrapper.css('height', `${$wrapper.outerHeight()}px`);
    $('.uncovr-news', $wrapper).remove();

    const region = mapRegion($wrapper.attr('data-region'));

    const newsList = (await loadNewsList(null, {
        take: 100,
        maxAgeHours: 168,
        ...(region ? { regions: region } : {})
    }, signal))
        .items
        .filter((news) => !!news.previewDate)
        .sort((a, b) => a.previewDate - b.previewDate);
    const newsEls = [];

    if (signal.aborted) return;
    if (!newsList.length) return;

    const makeGroup = () => {
        const newsSectionEl = $(`<section class="uncovr-news uncovr-news--by-date"></section>`);

        const dp = previousDay.split('.')
        const dateEl = $(`<div class="uncovr-news__date__wrapper">
                                <h2 class="uncovr-news__date"><strong>${daysOfTheWeek[dp[0]]}</strong>${dp[1].padStart(2, "0")}.${(parseInt(dp[2]) + 1).toString().padStart(2, "0")}.</h2>
                            </div>`);
        newsSectionEl.append(dateEl);

        newsSectionEl.append(newsEls.join(''));

        $wrapper.append(newsSectionEl);
        $wrapper.css('height', 'auto');

        newsEls.length = 0;

        handleNewsImages(newsSectionEl);
    }

    let previousDay = null;
    for (let news of newsList) {
        const newsItem = `<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}" style="--ressort-color: ${news.ressort.color}">
                    <img class="uncovr-news__item__image" data-src="${imageUrlForNews(news)}" alt="image accompanying press release">
                    <div class="uncovr-news__item__content">
                        <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                    </div>
                    <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                </article>`;

        let currentDay = `${news.previewDate.getDay()}.${news.previewDate.getDate()}.${news.previewDate.getMonth()}.${news.previewDate.getFullYear()}`;
        if (previousDay != currentDay) {
            if (previousDay) {
                makeGroup();
            }
            previousDay = currentDay;
        }
        newsEls.push(newsItem);
        if (news.id == newsList[newsList.length - 1].id) {
            makeGroup();
        }
    }
}

async function fillByTag($wrapper, signal) {
    $wrapper.css('height', `${$wrapper.outerHeight()}px`);
    $('.uncovr-news', $wrapper).remove();

    const region = mapRegion($wrapper.attr('data-region'));
    const ressortId = $wrapper.attr('data-ressort');
    const count = $wrapper.attr('data-count');

    const list = (await loadNewsList("tag", {
        resortId: ressortId,
        take: count || 12,
        ...(region ? { regions: region } : {})
    }, signal));

    for (let group of list) {
        const newsEls = [];

        if (signal.aborted) return;

        for (let news of group.items) {
            newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}">
                            <img class="uncovr-news__item__image" data-src="${imageUrlForNews(news)}" alt="image accompanying press release">
                            <div class="uncovr-news__item__content">
                                <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                            </div>
                            <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                        </article>`);
        }

        const newsSectionEl = $(`<section class="uncovr-news" style="--ressort-color:${group.items[0].ressort.color}"></section>`);
        const ressortEl = $(`<div class="uncovr-news__ressort__wrapper">
                        <h2 class="uncovr-news__ressort">${group.group.label}</h2>
                    </div>`);

        newsSectionEl.append(ressortEl);

        newsSectionEl.append(newsEls.join(''));

        $wrapper.append(newsSectionEl);
        $wrapper.css('height', 'auto');

        handleNewsImages(newsSectionEl);
    }
}

async function fillByRessort($wrapper, signal) {
    $wrapper.css('height', `${$wrapper.outerHeight()}px`);
    $('.uncovr-news', $wrapper).remove();

    const region = mapRegion($wrapper.attr('data-region'));
    const ressortId = $wrapper.attr('data-ressort');

    const ressortList = (await loadNewsList("ressort", {
        resortId: ressortId,
        take: 12,
        ...(region ? { regions: region } : {})
    }, signal));

    for (let group of ressortList) {
        const ressort = group.ressort;
        const newsEls = [];

        if (signal.aborted) return;

        let nc = 0;
        for (let news of group.items) {
            newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}">
                            <img class="uncovr-news__item__image" data-src="${imageUrlForNews(news)}" alt="image accompanying press release">
                            <div class="uncovr-news__item__content">
                                <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                            </div>
                            <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                        </article>`);
        }

        const newsSectionEl = $(`<section class="uncovr-news" style="--ressort-color:${ressort.color}"></section>`);
        const ressortEl = $(`<div class="uncovr-news__ressort__wrapper">
                        <h2 class="uncovr-news__ressort">${ressort.name}</h2>
                    </div>`);

        newsSectionEl.append(ressortEl);

        newsSectionEl.append(newsEls.join(''));

        $wrapper.append(newsSectionEl);
        $wrapper.css('height', 'auto');

        handleNewsImages(newsSectionEl);
    }
}

var cancelCurrentFillNews;
function fillNews(mode, $wrapper, ignoreGlobalMode) {
    if (cancelCurrentFillNews) {
        cancelCurrentFillNews();
        cancelCurrentFillNews = null;
    }

    const controller = new AbortController();

    if (mode == "date") {
        fillByDate($wrapper, controller.signal);
    } else if (mode == "preview") {
        fillByPreview($wrapper, controller.signal);
    } else if(mode == "tag") {
        fillByTag($wrapper, controller.signal);
    } else {
        fillByRessort($wrapper, controller.signal);
    }

    const cancel = () => {
        controller.abort();
    }

    cancelCurrentFillNews = cancel;

    return cancel;
}

var ytApiPromise = null;
function loadYTApi() {
    if (!ytApiPromise) {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/player_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        ytApiPromise = new Promise((resolve) => {
            window.onYouTubePlayerAPIReady = () => {
                resolve();
            }
        })
    }

    return ytApiPromise;
}

function setupVideo(el) {
    var videoWrappers = document.querySelectorAll('.uncovr-news-article__video');

    for (let videoWrapper of videoWrappers) {
        videoWrapper.addEventListener('click', (e) => {
            const id = e.currentTarget.getAttribute('data-video-id') || 'vo4rbhR_vsk';
            videoWrapper.classList.add('uncovr-news-article__video--loading');
            const paddingTop = parseInt(getComputedStyle(videoWrapper).paddingTop);
            console.log(videoWrapper.offsetHeight);
            loadYTApi().then(() => {
                var player = new YT.Player(videoWrapper.querySelector('.uncovr-news-article__video__placeholder').id, {
                    height: videoWrapper.offsetHeight - paddingTop,
                    width: videoWrapper.offsetWidth,
                    videoId: id,
                    playerVars: {
                        autoplay: 1,
                        showinfo: 0,
                        autohide: 1,
                        modestbranding: 1
                    },
                });
                player.addEventListener('onReady', () => {
                    videoWrapper.classList.remove('uncovr-news-article__video--loading');
                    videoWrapper.classList.add('uncovr-news-article__video--playing');
                    player.playVideo();
                })
            })
        })
    }
}

async function showNews(el, id) {
    const json = el.getAttribute("data-json");
    const news = await (json ? parseApiNews(JSON.parse(json)) : loadNews(id));

    el.style.setProperty("--ressort-color", news.ressort.color);

    $('.uncovr-news-article__video, .uncovr-news-article__info, .uncovr-news-article__content, .uncovr-news-article__image', el).remove();

    el.innerHTML += `
        ${news.video
        ? `<div class="uncovr-news-article__video" style="--image-src: url(${imageUrlForNews(news)})" data-video-id="${news.video}">
                <div class="uncovr-news-article__video__background"></div>
                <div id="youtube-player-1" class="uncovr-news-article__video__placeholder"></div>
            </div>`
        : (news.imageId ? `<img class="uncovr-news-article__image" src="${imageUrlForNews(news)}" alt="image accompanying press release">` : '<div></div>')}
        <div class="uncovr-news-article__content">
            <h1 class="uncovr-news-article__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h1>
            <div class="uncovr-news-article__text">${news.text.replace(/(https?:\/\/([^\s]+?)\/?(\s|$))/g, '<a href="$1" target="_blank">$2</a>$3')}</div>
            ${news.source ? `<div class="uncovr-news-article__source"><strong>Originalquelle</strong> <a href="${news.source}" target="_blank"><span class="icon icon-export"></span> ${news.source.replace(/https?:\/\//, '').split('/')[0]}</a></div>` : ''}
        </div>
        <div class="uncovr-news-article__info">
            <div class="uncovr-news-article__ressort-wrapper">
                <h2 class="uncovr-news-article__ressort">${news.ressort.name}</h2>
            </div>
            <div class="uncovr-news-article__info__content">
                <div class="uncovr-news-article__info__date">${news.date.getDate().toString().padStart(2, "0")}.${(news.date.getMonth() + 1).toString().padStart(2, "0")}.${news.date.getFullYear()}</div>
                <hr />
                ${news.mediaSource
    ? news.mediaSource.startsWith("http")
        ? `<div class="uncovr-news-article__info__media-source"><strong>Bildquelle</strong> <a href="${news.mediaSource}" target="_blank"><span class="icon icon-export"></span> ${news.mediaSource.replace(/https?:\/\//, '').split('/')[0]}</a></div>`
        : `<div class="uncovr-news-article__info__media-source"><strong>Bildrechte</strong> ${news.mediaSource}</div>`
    : ''
}
                <button class="black-bordered small" style="display: flex; gap: .5rem; font-weight: 300; font-size: .875rem" data-success="Kopiert!" data-error="Fehler!">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 1rem;">
                        <path d="M272 416C263.2 416 256 423.2 256 432V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V192c0-17.67 14.33-32 32-32h112C184.8 160 192 152.8 192 144C192 135.2 184.8 128 176 128H63.99c-35.35 0-64 28.65-64 64l.0098 256C0 483.3 28.65 512 64 512h160c35.35 0 64-28.65 64-64v-16C288 423.2 280.8 416 272 416zM502.6 86.63l-77.25-77.25C419.4 3.371 411.2 0 402.7 0H288C252.7 0 224 28.65 224 64v256c0 35.35 28.65 64 64 64h160c35.35 0 64-28.65 64-64V109.3C512 100.8 508.6 92.63 502.6 86.63zM416 45.25L466.7 96H416V45.25zM480 320c0 17.67-14.33 32-32 32h-160c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h96l.0026 64c0 17.67 14.33 32 32 32H480V320z"/>
                    </svg>
                    Meldungstext kopieren
                </button>

                <div class="uncovr-news-article__info__share">
                    <strong>Teilen</strong>
                    <div class="uncovr-news-article__info__share__buttons">
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(canonicalUrlForNews(news))}" target="_blank"><img src="/Content/images/logo_linkedin.svg" width="32" alt="linkedin logo"></a>
                        <a href="http://www.twitter.com/share?text=${encodeURIComponent(news.title)}&url=${encodeURIComponent(canonicalUrlForNews(news))}" target="_blank"><img src="/Content/images/logo_twitter.svg" width="24" alt="X (Twitter) logo"></a>
                        <a href="mailto:?subject=${encodeURIComponent('uncovr news: ' + news.title)}&body=${encodeURIComponent(canonicalUrlForNews(news))}"><img src="/Content/images/icon-email.svg" width="36" alt="E-Mail Icon"></a>
                        <a href="https://wa.me/?text=${encodeURIComponent(canonicalUrlForNews(news))}" target="_blank"><img src="/Content/images/logo_whatsapp.svg" width="28" alt="WhatsApp Logo"></a>
                    </div>
                </div>
            </div>
        </div>
        <aside class="uncovr-news-article__more">
            <h1 class="uncovr-news-article__more__title">Mehr aus <strong>${news.ressort.name}</strong></h1>
            <div class="uncovr-news-article__more__items uncovr-news-article__more__items--vertical"></div>
        </aside>
    `;

    $("button", el).off().on('click', async function (ev) {
        ev.currentTarget.classList.remove("success", "error");
        try {
            await navigator.clipboard.writeText(`${news.title}\n\n${news.text}`);
            ev.currentTarget.offsetHeight;
            $(ev.currentTarget).addClass("success");
        } catch (error) {
            console.error(error.message);
            $(ev.currentTarget).addClass("error");
        }
    });

    setupVideo(el);

    return news;
}
export function initNews() {
    //init news lists
    const uncovrNewsWrappers = $('.uncovr-news__wrapper');
    uncovrNewsWrappers.each((idx, wrapper) => {
        const $wrapper = $(wrapper);
        let mode = $wrapper.attr("data-mode");
        fillNews(mode, $wrapper, $wrapper.attr("data-toggles") == "false");
    });

    //init news details
    const uncovrNewsDetails = $('.uncovr-news-article[data-id]');
    uncovrNewsDetails.each(async (idx, el) => {
        const newsId = el.getAttribute("data-id").split('-').pop();
        const news = await showNews(el, newsId);


        let currentNewsIndex = -1;
        let newsList;
        function showNextNews(dir) {
            if (newsList && currentNewsIndex > -1) {
                currentNewsIndex = currentNewsIndex + dir;
                if (currentNewsIndex < 0) currentNewsIndex = newsList.length - 1;
                else if (currentNewsIndex >= newsList.length - 1) currentNewsIndex = 0;
                const news = newsList[currentNewsIndex];
                showNews(el, news.id);
                window.history.pushState({}, "", canonicalUrlForNews(news));
            }
        }

        const moreAside = $('.uncovr-news-article__more .uncovr-news-article__more__items');

        //fetch more
        loadNewsList(null, { resortId: news.ressort.id, take: news.imageId ? 12 : 6 }).then(async function (d) {
            newsList = d.items;
            newsList = shuffleArray(newsList);
            const currentNews = news;
            let idx = -1;
            for (let news of newsList) {
                idx++;

                if (currentNews.id == news.id) {
                    currentNewsIndex = idx;
                    continue;
                }

                moreAside[0].innerHTML += `
                    <a class="uncovr-news-article__more__item" href="${canonicalUrlForNews(news)}">
                        <div class="uncovr-news-article__more__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</div>
                    </a>
                `;
            }
        });

        let maybeSwiping = null;
        el.addEventListener('touchstart', (e) => {
            if (e.touches.length === 1) {
                maybeSwiping = {
                    time: Date.now(),
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                    lastTime: Date.now(),
                    lastX: e.touches[0].clientX,
                    lastY: e.touches[0].clientY,
                    dx: 0,
                    dy: 0,
                    vx: 0,
                    vy: 0,
                }
            } else {
                maybeSwiping = null;
            }
        })
        el.addEventListener('touchmove', (e) => {
            if (maybeSwiping) {
                const dx = e.touches[0].clientX - maybeSwiping.lastX;
                const dy = e.touches[0].clientY - maybeSwiping.lastY;
                if (2 * Math.abs(dx) > Math.abs(dy)) {
                    const dt = Math.max(1, Date.now() - maybeSwiping.lastTime);
                    maybeSwiping.dx = dx;
                    maybeSwiping.dy = dy;
                    maybeSwiping.vx = (maybeSwiping.vx + dx / dt) / 2;
                    maybeSwiping.vy = (maybeSwiping.vy + dy / dt) / 2;
                    maybeSwiping.lastTime = Date.now();
                    maybeSwiping.lastX = e.touches[0].clientX;
                    maybeSwiping.lastY = e.touches[0].clientY;
                    e.preventDefault();
                } else {
                    maybeSwiping = null
                }
            }
        })
        el.addEventListener('touchend', (e) => {
            if (maybeSwiping) {
                const dx = maybeSwiping.x - maybeSwiping.lastX;
                const dy = maybeSwiping.y - maybeSwiping.lastY;
                if (
                    Date.now() - maybeSwiping.time > 100 &&
                    Math.abs(dx) > 3 * Math.abs(dy) &&
                    Math.abs(maybeSwiping.vx) > .1
                ) {
                    showNextNews(Math.sign(maybeSwiping.vx) * -1)
                }
            }
        })
        el.addEventListener('touchcancel', (e) => {
            maybeSwiping = null;
        })
    });

    //copy handling
    $("button[data-action='copy']").off().on('click', async function (ev) {
        ev.currentTarget.classList.remove("success", "error");
        try {
            await navigator.clipboard.writeText(ev.currentTarget.closest('.uncovr-news-article').querySelector('.uncovr-news-article__content').innerText);
            ev.currentTarget.offsetHeight;
            $(ev.currentTarget).addClass("success");
        } catch (error) {
            console.error(error.message);
            $(ev.currentTarget).addClass("error");
        }
    });

    //new swiper
    $('.uncovr-news-swiper').each(async (idx, el) => {
        const $wrapper = $(el);
        const region = mapRegion($wrapper.attr('data-region'));
        const count = $wrapper.attr('data-count');
        const ressortId = $wrapper.attr('data-ressort');
        const top = $wrapper.attr('data-top') === 'true';
        
        const swiper = new Swiper(el.querySelector('.swiper'), {
            speed: 400,
            modules: [Pagination, Autoplay],
            //loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
                stopOnLastSlide: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });

        const newsList = (await loadNewsList(null, {
            take: count ?? 4,
            ...(region ? { regions: region } : {}),
            ...(top ? { topNews: 'true' } : {}),
            ...(ressortId ? { resortId: ressortId } : {}),
        })).items

        const withImage = $wrapper.hasClass('uncovr-news-swiper--ressort');

        $wrapper.find('.swiper-wrapper').append(newsList.map((news) => {
            return withImage ? `
                <div class="swiper-slide" style="--ressort-color: ${news.ressort.color}">
                    <a href="${canonicalUrlForNews(news)}" class="uncovr-news-swiper__link">
                        <div class="uncovr-news-swiper__topic">${news.ressort.name}</div>
                        <div class="uncovr-news-swiper__headline">${news.title}</div>
                        <div class="uncovr-news-swiper__text">${news.text.split(' ').slice(0, 50).join(' ') + '...'}</div>
                        <div class="uncovr-news-swiper__source">${news.source ? news.source.replace(/https?:\/\//, '').split('/')[0] : ''}</div>
                        <img class="uncovr-news-swiper__image" src="${imageUrlForNews(news)}" />
                    </a>
                </div>
            ` : `
                <div class="swiper-slide" style="--ressort-color: ${news.ressort.color}">
                    <a href="${canonicalUrlForNews(news)}" class="uncovr-news-swiper__link">
                        <div class="uncovr-news-swiper__topic">${news.ressort.name}</div>
                        <div class="uncovr-news-swiper__headline">${news.title}</div>
                    </a>
                </div>
            `;
        }));

        swiper.updateSlides();
    });

    //recent news
    $('.uncovr-news-headlines').each(async (idx, el) => {
        const $wrapper = $(el);

        const region = mapRegion($wrapper.attr('data-region'));
        const ressortId = $wrapper.attr('data-ressort');
        const exclude = $wrapper.attr('data-exclude');
        const top = $wrapper.attr('data-top') == "true";
        const mode = $wrapper.attr('data-mode') || 'ressort';

        const newsList = (await loadNewsList(mode, {
            take: 50,
            ...(top ? { topNews: 'true' } : {}),
            ...(region ? { regions: region } : {}),
            ...(ressortId ? { resortId: ressortId } : {}),
            ...(exclude ? { excludeResortId: exclude } : {}),
        }));

        const news = [];

        $wrapper.append(newsList.map((g) => {
            const groupHead = mode == "ressort"
                ? `<h2 class="uncovr-news-headlines__title uncovr-news-headlines__title--ressort" style="--ressort-color: ${g.ressort.color}"><a href="/Articles/Ressort/${encodeURIComponent(g.ressort.name)}-${ g.ressort.id }">${g.ressort.name}</a></h2>`
                : mode == "date"
                    ? `<h2 class="uncovr-news-headlines__title uncovr-news-headlines__title--date"><strong>${daysOfTheWeek[g.date.getDay()]}</strong>${g.date.getDate().toString().padStart(2, "0")}.${(g.date.getMonth() + 1).toString().padStart(2, "0")}.</h2>`
                    : `<h2 class="uncovr-news-headlines__title uncovr-news-headlines__title--ressort" style="--ressort-color: ${g.items[0].ressort.color}">${g.group.label}</h2>`

            news.push(...g.items);

            return `
                <div class="uncovr-news-headlines__group">
                    ${groupHead}
                    <ul class="uncovr-news-headlines__list">
                    ${
                        g.items.map((item) => `<li class="uncovr-news-headlines__list__item" id="news-${item.id}" data-id="${item.id}"><a href="${canonicalUrlForNews(item)}">${item.title}${(item.imageId || item.video) ? `<img src="/Content/images/icon-camera.svg" alt="hat Bilder Icon" />` : ''}</a></li>`).join('')
                    }
                    </ul>
                </div>
            `;
        }));

        function openNews($parent) {
            if (!$parent.length) return;
            const id = $parent.attr('data-id');
            window.location.hash = $parent.attr('id');
            const newsItem = news.find((n) => n.id == id);

            if ($parent.hasClass('uncovr-news-headlines__list__item--open')) {
                $parent.removeClass('uncovr-news-headlines__list__item--open').find('.uncovr-news-headlines__list__item__content, .uncovr-news-headlines__list__item__links').remove();
                return;
            }

            $('.uncovr-news-headlines__list__item--open').removeClass('uncovr-news-headlines__list__item--open').find('.uncovr-news-headlines__list__item__content, .uncovr-news-headlines__list__item__links').remove();

            $parent.addClass('uncovr-news-headlines__list__item--open');
            $parent.append(`<div class="uncovr-news-headlines__list__item__content">
                ${newsItem.text}
            </div>`);
            $parent.append(`<div class="uncovr-news-headlines__list__item__links">
                <a href="${canonicalUrlForNews(newsItem)}" target="_blank">Artikelview</a>
                <a href="${newsItem.source}" target="_blank">${newsItem.source.replace(/https?:\/\//, '').split('/')[0]}</a>
            </div>`);

            $parent[0].scrollIntoView();
        }

        $wrapper.find('.uncovr-news-headlines__list__item a').off().on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const $parent = $(e.currentTarget.parentElement);
            openNews($parent);
        });

        if (window.location.hash.startsWith("#news-")) {
            openNews($(window.location.hash));
            
        }
    });
}