/**
* various helper functions
*/

export function padZero(num) {
    if (num < 10) return '0' + num;
    return num;
}

export function fdate(date, withTime = true) {
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}` + (withTime ? ` ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}` : '');
}

export function fdateApi(date, withTime = true) {
    return `${padZero(date.getDate())}-${padZero(date.getMonth() + 1)}-${date.getFullYear()}` + (withTime ? ` ${padZero(date.getHours())}h${padZero(date.getMinutes())}m${padZero(date.getSeconds())}` : '');
}

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}